<template>

  <div>

    <!--
    <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-xl text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-screen-xl sm:w-full">
          <div class="grid grid-cols-3 content-center">
            <div class="col-span-2">
              <img src="https://app.news.travel/v1/storage/files/61d2deccb9c39/preview?project=619e27fe198b7">
            </div>
            <div class="p-8">02</div>
          </div>
        </div>
      </div>
    </div>
    -->

    <h1 class="pt-12 text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">{{property.name}} &mdash; Collage</h1>
    <div class="collage grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-8 2xl:mx-60">
      <div v-for="post in posts" :key="post.$id" class="bg-white rounded-lg overflow-hidden transition duration-200 ease-in-out transform shadow-xl">
        <post :post="post" format="slider"/>
      </div>
    </div>

    <h1 class="pt-12 text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">{{property.name}} &mdash; Slider</h1>
    <div class="-mx-4">
      <hooper :settings="hooperSettings">
        <slide v-for="post in posts" :key="post.$id">
          <div class="bg-white rounded-lg overflow-hidden transition duration-200 ease-in-out transform shadow-xl mx-4">
            <post :post="post" format="slider"/>
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>

    <h1 class="pt-24 text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">{{property.name}} &mdash; Centered Slider</h1>
    <div class="overflow-hidden">
      <div class="-mx-4">
        <hooper :settings="hooperSettingsCenter">
          <slide v-for="post in posts" :key="post.$id">
            <div class="bg-white rounded-lg overflow-hidden transition duration-200 ease-in-out transform shadow-xl mx-4">
              <post :post="post" format="slider"/>
            </div>
          </slide>
          <hooper-navigation slot="hooper-addons"></hooper-navigation>
        </hooper>
      </div>
    </div>

    <h1 class="mt-24 text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">{{property.name}} &mdash; Gallery</h1>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 lg:gap-8">
      <div v-for="post in posts" :key="post.$id" class="bg-white rounded-lg overflow-hidden transition duration-200 ease-in-out transform shadow-xl">
        <post :post="post" format="grid"/>
      </div>
    </div>

    <h1 class="mt-24 text-xl md:text-2xl lg:text-3xl font-bold mb-4 text-center">{{property.name}} &mdash; Masonry</h1>
    <div class="md:masonry-2-col lg:masonry-3-col xl:masonry-4-col box-border mx-auto before:box-inherit after:box-inherit">
      <div v-for="post in posts" :key="post.$id" class="break-inside bg-white rounded-lg overflow-hidden transition duration-200 ease-in-out transform shadow-xl">
        <post :post="post" format="masonry"/>
      </div>
    </div>

    <br/><br/>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Query } from 'appwrite'
import Post from '../components/Post.vue'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'Feed',
  components: {
    Post,
    Hooper,
    Slide,
    HooperNavigation
  },
  data () {
    return {
      modal: false,
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
        breakpoints: {
          640: {
            centerMode: false,
            itemsToShow: 2,
            infiniteScroll: true,
            wheelControl: false
          },
          768: {
            centerMode: false,
            itemsToShow: 3,
            infiniteScroll: true,
            wheelControl: false
          },
          1024: {
            centerMode: false,
            itemsToShow: 4,
            itemsToSlide: 2,
            infiniteScroll: true,
            wheelControl: false
          },
          1280: {
            centerMode: false,
            itemsToShow: 5,
            itemsToSlide: 3,
            infiniteScroll: true,
            wheelControl: false
          }
        }
      },
      hooperSettingsCenter: {
        itemsToShow: 1,
        centerMode: true,
        wheelControl: false,
        breakpoints: {
          640: {
            centerMode: true,
            itemsToShow: 2.05,
            infiniteScroll: true,
            wheelControl: false
          },
          768: {
            centerMode: true,
            itemsToShow: 3.5,
            infiniteScroll: true,
            wheelControl: false
          },
          1024: {
            centerMode: true,
            itemsToShow: 4,
            itemsToSlide: 2,
            infiniteScroll: true,
            wheelControl: false
          },
          1280: {
            centerMode: true,
            itemsToShow: 5.75,
            itemsToSlide: 3,
            infiniteScroll: true,
            wheelControl: false
          }
        }
      }
    }
  },
  mounted () {
    const queries = []
    queries.push(Query.equal('propertyId', this.property.$id))
    this.fetchPosts({
      queries: queries
    })
  },
  computed: {
    ...mapGetters(['properties', 'posts', 'getAccount']),
    property () {
      const index = this.properties.findIndex(x => x.$id === this.$route.params.feed_id)
      return this.properties[index]
    }
  },
  methods: {
    ...mapActions(['fetchPosts', 'clearPosts'])
  },
  destroyed () {
    this.clearPosts()
  }
}
</script>

<style>
.hooper {
  height:auto !important;
}
.hooper-next,
.hooper-prev {
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 9999px;
  border: none;
  padding: 0.25rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.hooper-next:hover,
.hooper-prev:hover {
  background-color: rgba(255, 255, 255, .9);
}
.hooper-next.is-disabled,
.hooper-prev.is-disabled {
  opacity: 0.3;
  cursor: not-allowed;
}
.hooper-next {
  right: 1.5rem;
}
.hooper-prev {
  left: 1.5rem;
}
</style>
